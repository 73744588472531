<template>
  <div>
      <div class="iframelist" ref="container"></div>
      <!-- <div v-if="circlethree" class="progress"><el-progress type="circle"  color="#6149f6" :percentage="numberlist"></el-progress></div> -->
  </div>
</template>
<script>
import * as Three from 'three'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import {
OrbitControls
} from 'three/examples/jsm/controls/OrbitControls'
// 
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
// 动画库
import gsap from 'gsap'
    var scene=null,
    numberlist=null,
    camera=null,
    renderer=null,
    // loader=null,
    install=null
export default {
  data(){
      return{
        circlethree:true,
        numberlist:'',
        time:''
      }
  },
  methods:{
      init(){
    let container=this.$refs.container
    console.log(container)
    camera = new Three.PerspectiveCamera(70, container.clientWidth/container.clientHeight, 0.01, 1000);
    // 相机的位置
    var vector = new Three.Vector3( 0, 0, - 1 );
    vector.applyQuaternion( camera.quaternion );
    // angle = vector.angleTo( target.position )
    // console.log(vector)
    // camera.position.z = -150
    camera.position.z = -1
    camera.position.y=0
    camera.position.x=0
    console.log(vector,camera.position)
    // 场景
    scene = new Three.Scene()
    // 初始化渲染器
    renderer = new Three.WebGLRenderer({antialias:true});
    // 渲染器的大小
    renderer.setSize(container.clientWidth,container.clientHeight);
    // 设置背景透明
    // 设置透明
    renderer.setClearAlpha(0.1)
    // 将渲染器添加到页面
    container.appendChild(renderer.domElement);
    var gltfLoader = new GLTFLoader();
		// var group = new Three.Group
		const dracoLoader = new DRACOLoader();
		// 设置解压路径，这个文件直接放在项目的public目录下面即可，后面会截图指明gltf目录所在位置
		dracoLoader.setDecoderPath('static/gltf/');
		dracoLoader.setDecoderConfig({ type: 'js' });
		dracoLoader.preload();
		gltfLoader.setDRACOLoader(dracoLoader);

    
    // loader = new GLTFLoader();
    // var numberlist=this.numberlist
    // var mesh=this.mesh
    gltfLoader.load( 'static/glb/1313.glb', function ( glb ) {
    console.log(glb);
    glb.scene.position.y = -0.7;
    // glb.scene.position.x = 20;
    // glb.scene.rotation.y=Math.PI*1.5
    glb.scene.traverse( function ( child ) {
      if ( child.isMesh ) {
          // child.frustumCulled = false;
          // //模型阴影
          // child.castShadow = true;
          //模型自发光
          child.material.emissive =  child.material.color;
          child.material.emissiveMap = child.material.map ;
      }
    })
    scene.add(glb.scene);

    // console.log(scene)
    },function ( xhr ) {
      numberlist=Math.floor(xhr.loaded / xhr.total * 100)
      // 控制台查看加载进度xhr
      // 通过加载进度xhr可以控制前端进度条进度   Math.floor:小数加载进度取整
      // console.log('加载进度'+numberlist+'%',xhr.loaded/ xhr.total * 100 );
    })
    // loader.load( 'static/glb/333.glb', function ( glb ) {
    // console.log(glb);
    // glb.scene.scale.set(10,10,10)
    // glb.scene.position.y = -30;
    // // glb.scene.position.x = 0;
    // glb.scene.position.z = 40;
    // glb.scene.traverse( function ( child ) {
    //   if ( child.isMesh ) {
    //       child.frustumCulled = false;
    //       //模型阴影
    //       child.castShadow = true;
    //       //模型自发光
    //       child.material.emissive =  child.material.color;
    //       child.material.emissiveMap = child.material.map;
    //     }
    // })
    // scene.add(glb.scene);
    // })
      // 方向光
      var light1 = new Three.AmbientLight(0xffffff ,0.5);//绿
      light1.position.set(0,0,-90);
      scene.add(light1);

    //   // 方向光
      var light2 = new Three.SpotLight("#fff",0.5);//红
      light2.position.set(0,0,-90);
     scene.add(light2);
      var direction = new Three.DirectionalLight("#fff",0.9);
      // light1.position.set(0,0,0);
     scene.add(direction);
    // var light2 = new Three.SpotLight("#fff",0.3);//红
    // light2.position.set(0,-10,-200);
    // scene.add(light2);
    // var light = new Three.DirectionalLight("#f6ffdc",0.3); //添加了一个白色的平行光
    // // scene.add(new Three.AmbientLight("#fff",0.8));
    // scene.add( light );
    // console.log(this.numberlist)
    const OrbitControl = new OrbitControls(camera, renderer.domElement)
      // // 是否允许缩放
      OrbitControl.enableZoom = false;
    // 设置轨道自然
    OrbitControl.enableDamping = true
    // 设置惯性
    OrbitControl.update()
    renderer.setClearColor('#fff', 0.1);

  },
  animate(){
    // 浏览器自动渲染下一帧
    requestAnimationFrame(this.animate);
    // var animationMixer = new Three.AnimationMixer(scene);
    // 渲染到页面
    renderer.render(scene,camera); 
    
  },
  
    // console.log(amtion)
    // animationMixer.update( clock.getDelta() )
  

  },
  mounted(){
      this.init()
      this.animate()
  },
  created(){
    // this.circlethree=true
    // this.time=setInterval(this.numberthree,10)
  }
  
}
</script>
<style lang="less" scoped>
.iframelist {
width: 100vw;
height: 100vh;
}
.progress {
    position: absolute;
    top: 30%;
    left: 30%;
    // margin: -50% -50%;
    
  }
</style>
