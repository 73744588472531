<template>
  <div class="trade">
    <!-- 功能 -->
   <div class="sendlist">
    <div class="contenttrade">
      <div class="soncontent"  v-for="(item,index) in trade" :key="index" @click="gotoson(index)">
        <div v-if="index==avent" class="sendweb">{{ item.title }}</div>
        <div v-if="index!=avent" class="sendwebtow">{{ item.title }}</div>
        <!-- <div  class="bordersen"></div> -->
      </div>
    </div>
    <div class="content">
      <div class="contettitle">
        <div class="title">医学培训痛点</div>
        <div class="titleson">
          <div class="onelist" v-for="(item,index) in itemtitletow" :key="index">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="arrowhead">
        <div class="oblong">
          <img src="../../../../image/Rectangle7.png"  srcset="../../../../image/Rectangle7@2x.png  2x," alt="">
        </div>
        <div class="triangle">
          <img src="../../../../image/Slice5.png"  srcset="../../../../image/Slice5@2x.png  2x," alt="">
        </div>
      </div>
      <div class="contettitles">
        <div class="title">医知医汇解决方案</div>
        <div class="titleson">
          <div class="onelist" v-for="(item,index) in itemtitletows" :key="index">
            {{ item.title }}
          </div>
        </div>
        <div class="backimage">
          <img src="../../../../image/2023032301.png" alt="">
        </div>
      </div>
    </div>
   </div>
   <!-- 功能特色 -->
   <div class="sendlist" id="Positions1">
    <div class="characteristic">功能特色</div>
    <div class="introduce">紧贴客户场景为客户提供丰富的直播体验</div>
    <div class="imagechaarcter">
      <div class="image" v-for="(item,index) in imagesweb" :key="index" @mouseenter="imagetodo(index)">
        <div class="listimageweb">
          <img :src="item.image" alt="">
        </div>
        <div class="onelist" v-if="index==imagelistbox">
           <div class="listtodo">
            <div class="poread">
              {{ item.names }}
            </div>
            <div class="sendtow">{{ item.title }}</div>
          </div></div>
        <div class="towlist" v-else> 
          <div class="listtodo">
            <div class="poreads">
              {{ item.names }}
            </div>
            <div class="sendtows">{{ item.title }}</div>
          </div></div>
      </div>
      <!-- <div class="image" v-for="(item,index) in imagesweb" :key="index" @mouseenter="imagetodo(index)">
        <div :class="item.class" v-show="index==imagelistbox"><img :src="item.image" alt="">
          <div class="listtodo">
            <div class="poread">
              {{ item.names }}
            </div>
            <div class="sendtow">{{ item.title }}</div>
          </div>
        </div>
        <div :class="item.class" v-show="index!=imagelistbox"><img :src="item.image" alt="">
          <div class="listtodo">
            <div class="poreads">
              {{ item.names }}
            </div>
            <div class="sendtows">{{ item.title }}</div>
          </div>
        </div>
      </div> -->
    </div>
   </div>
   <!-- 应用场景 -->
   <div class="sendlist" id="Positions2">
    <div class="characteristic">应用场景</div>
    <div class="introduce">满足用户多种直播场景需求</div>
    <div class="stepsend">
      <div class="widstep" >
        <div class="titlesend" >
          <div class="sendpad" v-for="(item,index) in sonline " :key="index" @click="gotonumber(index,item)">
            <span v-if="sonlinenumber>index" style="color: #6352F9;">{{ item.title }}</span>
            <span v-else style="color: #000;">{{ item.title }}</span>
          </div>
        </div>
        <div class="line">
          <div class="oneceshi">
            <div class="sonwidth" :style="{width:`${sonlinewidth}px`}"></div>
            <div class="listweb" v-for="(item,index) in sonline " :key="index" >
              <div class="cilcer" v-if="sonlinenumber>index" :style="{left:`${item.sonlistwidth}px`}" @click="gotonumber(index,item)"></div>
              <div class="cilcers" v-else :style="{left:`${item.sonlistwidth}px`}" @click="gotonumber(index,item)"></div>
            </div>
          </div>
          <!-- <div class="send" v-for="(item,index) in sonlines " :key="index" @click="gotonumber(index,item)">
            <div class="gotoweb" v-if="sonlinenumber>index" :style="{width:item.width}"></div>
            <div class="gotowebtow" v-else :style="{width:item.width}"></div>
            <div class="cil" style="width: 14px;height: 14px;">
              <div class="cilcer" v-if="sonlinenumber>index && index<4"></div>
              <div class="cilcers" v-if="index<4 ||sonlinenumber == index &&sonlinenumber<4"></div>
            </div>
          </div> -->
        </div>
        <div class="number">
          <div class="sendpadlist"  v-for="(item,index) in sonline " :key="index" @click="gotonumber(index,item)">
            <div class="wadlist" :style="{width:item.width}"></div>
            <div class="opsend" >
              <span v-if="sonlinenumber>index" style="color: #6352F9;">{{ item.number }}</span>
              <span v-else style="color: #000;">{{ item.number }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="charaimage">
      <div class="charaterimage">
        <div class="chartwebcontent" v-for="(item,index) in scenelist.image" :key="index">
          <div v-if="index==0" class="imageone" @click="imagewebimg(item,scenelist.image)">
            <img :src="item.image" alt="">
          </div>
          <div v-else-if="index==1" class="imagetow" @click="imagewebimg(item,scenelist.image)">
            <img :src="item.image" alt="">
          </div>
          <div v-else-if="index==2" class="imagethree" @click="imagewebimg(item,scenelist.image)">
            <img :src="item.image" alt="">
          </div>
        </div>
        
      </div>
      <div class="charatertitle">
        <div class="contenttitle">{{ scenelist.title }}</div>
        <div class="contentlist">{{ scenelist.contentlist }}</div>
        <div class="contentspan" v-for="(item,index) in scenelist.contentspan" :key="index">{{ item.title }}</div>
      </div>
    </div>
   </div>
   <!-- 合作伙伴 -->
   <div class="sendlist" id="Positions3">
    <div class="characteristic">合作客户</div>
    <div class="introduce">这些企业正在使用医知医汇服务</div>
    <div class="cooperate">
     <div class="hezuoflex">
      <div class="imagebacklistweb" v-for="(item,index) in imagehezuo" :key="index">
        <img :src="item.image" alt="">
      </div>
     </div>
    </div>
   </div>
   <!--  -->
  </div>
</template>
<script>
export default {
  data(){
    return {
      trade:[
        {id:0,title:'行业痛点'},
        {id:0,title:'功能特色'},
        {id:0,title:'应用场景'},
        {id:0,title:'合作客户'},
      ],
      avent:0,
      itemtitletow:[
        {id:0,title:' 医学专家时间成本高，国外专家到现场培训的成本更高，医生学员也都非常忙'},
        {id:1,title:' 医生有培训和学分的要求，获得学分需要学习时长和掌握培训知识'},
        {id:2,title:' 医学不断发展，广大医生渴望学习到新知识、新技能，最好能通过培训解决一些实际问题'},
        {id:3,title:' 医学企业想达到更好的推广的效果'},
        {id:4,title:' 医学专家集中在国内的一些知名医院，绝大多数医院内部培训师资不足'},
      ],
      itemtitletows:[
        {id:0,title:' 现场直播和会场连线让远途的医学专家免去了舟车劳顿，即使是大洋彼岸也感觉是近在咫。对于学员来讲，可以观看直播和回放，更加灵活。'},
        {id:1,title:' 报名表、签到、参会记录等功能让培训满足学分要求'},
        {id:2,title:' 国内外医学大咖的学术讲座带来前沿技术'},
        {id:3,title:' 品牌露出和专题讲座平衡学术会议和企业推广的需求'},
        {id:4,title:' 通过直播参加大咖讲座更加容易'},
      ],
      imagesweb:[
        {id:0,class:'onelist',names:'多种形式',title:'大班课、小班课、线上讲座、线下学术论坛、技术峰会、学会会议、研讨会、培训讲座、手术示教',image:require('../../../../image/Live7C_8cf7fdd91.png')},
        {id:1,class:'towlist',names:'丰富互动',title:'国内外、多地专家都可以实时连线互动，学员与专家之间也可以提问和互动答疑',image:require('../../../../image/Live7_ba34a7fd1.png')},
        {id:2,class:'towlist',names:'数据安全',title:'分布式数据存储和加强的数据安全策略来充分的保证数据安全',image:require('../../../../image/Live7_f174cde01.png')},
        {id:3,class:'towlist',names:'直播回看',title:'更灵活的参会方式，可针对性回看并加深理解',image:require('../../../../image/Medial204494011.png')},
        {id:3,class:'towlist',names:'营销转化',title:'扩大营销范围、有效提高转化率',image:require('../../../../image/Live7C_8cf7fdd91.png')},
      ],
      imagelistbox:0,
      imagetimer:'',
      active:0,
      sonline:[
        {id:0,width:'112px',number:'01',sonlistwidth:131,title:'医学会议'},
        {id:1,width:'224px',number:'02',sonlistwidth:400,title:'医学培训'},
        {id:2,width:'217px',number:'03',sonlistwidth:669,title:'手术示教'},
        {id:3,width:'224px',number:'04',sonlistwidth:947,title:'远程医疗直播'},
      ],
      sonlinewidth:131,
      // sonlines:[
      //   {id:0,width:'131px',title:'医学会议'},
      //   {id:1,width:'254px',title:'医学培训'},
      //   {id:2,width:'254px',title:'手术示教'},
      //   {id:3,width:'268px',title:'远程医疗直播'},
      //   {id:4,width:'145px',title:'远程医疗直播'},
      // ],
      sonlinenumber:1,
      scenelist:{id:0,title:'医学会议',contentlist:'医疗峰会讨论，专家讲座，健康科普',contentspan:[
          {id:0,title:'万人稳定直播'},
          {id:1,title:'营销推广裂变'},
          {id:2,title:'标准化驻场服务'},
          {id:3,title:'个性化品牌定制'},
        ],image:[
        {id:0,image:require('../../../../image/医学会议/0103.jpg')},
        {id:1,image:require('../../../../image/医学会议/0105.jpg')},
        {id:2,image:require('../../../../image/医学会议/0106.jpg')},
        ]
      },
      Overview:[
        {id:0,title:'医学会议',contentlist:'医疗峰会讨论，专家讲座，健康科普',contentspan:[
          {id:0,title:'万人稳定直播'},
          {id:1,title:'营销推广裂变'},
          {id:2,title:'标准化驻场服务'},
          {id:3,title:'个性化品牌定制'},
        ],image:[
        {id:0,image:require('../../../../image/医学会议/0103.jpg')},
        {id:1,image:require('../../../../image/医学会议/0105.jpg')},
        {id:2,image:require('../../../../image/医学会议/0106.jpg')},
        ]
      },
      {id:1,title:'医学培训',contentlist:'医学知识培训，医学考证培训，健康科普',contentspan:[
          {id:0,title:'培训形式多样'},
          {id:1,title:'丰富互动'},
          {id:2,title:'营销转化'},
          {id:3,title:'版权保护'},
        ],image:[
        {id:0,image:require('../../../../image/医学培训/04c03.jpg')},
        {id:1,image:require('../../../../image/医学培训/0101.jpg')},
        {id:2,image:require('../../../../image/医学培训/0104.jpg')},
        ]
      },
      {id:2,title:'手术示教',contentlist:'手术示教，器械培训',contentspan:[
          {id:0,title:'极清画质'},
          {id:1,title:'医疗设备接入'},
          {id:2,title:'丰富互动'},
          {id:3,title:'录制回放'},
        ],image:[
        {id:0,image:require('../../../../image/医学示教/3.3.jpg')},
        {id:1,image:require('../../../../image/医学示教/04c04.jpg')},
        {id:2,image:require('../../../../image/医学示教/0107.jpg')},
        ]
      },
      {id:3,title:'远程医疗直播',contentlist:'医患会诊，医学知识科普',contentspan:[
          {id:0,title:'多终端简易操作'},
          {id:1,title:'无延迟实时会诊'},
          {id:2,title:'一对多连麦会诊'},
          {id:3,title:'多样化互动会诊'},
        ],image:[
        {id:0,image:require('../../../../image/02case061.png')},
        {id:1,image:require('../../../../image/02case041.png')},
        {id:2,image:require('../../../../image/02case051.png')},
        ]
      }
      ],
      // /定时器
      timer:'',
      // 合作客户
      imagehezuo:[
        {id:0,image:require('../../../../image/MacBook Air - 3_slices/1@2x.png')},
        {id:1,image:require('../../../../image/MacBook Air - 3_slices/2@2x.png')},
        {id:2,image:require('../../../../image/MacBook Air - 3_slices/3@2x.png')},
        {id:3,image:require('../../../../image/MacBook Air - 3_slices/4@2x.png')},
        {id:4,image:require('../../../../image/MacBook Air - 3_slices/5@2x.png')},
        {id:5,image:require('../../../../image/MacBook Air - 3_slices/6@2x.png')},
        {id:6,image:require('../../../../image/MacBook Air - 3_slices/7@2x.png')},
        {id:7,image:require('../../../../image/MacBook Air - 3_slices/8@2x.png')},
        {id:8,image:require('../../../../image/MacBook Air - 3_slices/9@2x.png')},
        {id:9,image:require('../../../../image/MacBook Air - 3_slices/10@2x.png')},
        {id:10,image:require('../../../../image/MacBook Air - 3_slices/11@2x.png')},
        {id:11,image:require('../../../../image/MacBook Air - 3_slices/12@2x.png')},
        {id:12,image:require('../../../../image/MacBook Air - 3_slices/13@2x.png')},
        {id:13,image:require('../../../../image/MacBook Air - 3_slices/14@2x.png')},
        {id:14,image:require('../../../../image/MacBook Air - 3_slices/15@2x.png')},
      ],
      timelist:''
    }
  },
  methods:{
    gotoson(e){
      this.avent=e
      console.log(e)
      if(e!=0){
        document .getElementById(`Positions${e}`) .scrollIntoView({ behavior: 'smooth' })
      }
    },
    imagetodo (e){
      this.imagelistbox=e
      clearInterval(this.yingyongweblist)

    },
    gotonumber(e,a){
      clearInterval(this.timelist)
      console.log(e,a)
      let c=a.sonlistwidth+1
      this.sonlinewidth=c
      this.sonlinenumber=e+1
      this.scenelist=this.Overview[e]
    },
    // 点击显示哪一张
    imagewebimg(e,c){
      // console.log(e,a,c)
     
      c.push(c.splice(e, 1)[0])
    },
    // 测试 
    one(){
      // console.log('1')
      this.timelist=setInterval(()=>{
        // this.sonline=this.sonline+1
        if(this.sonlinenumber!=4){
           this.sonlinenumber=this.sonlinenumber+1
           let c=this.sonlinenumber-1
          this.sonlinewidth=this.sonline[c].sonlistwidth+1
          this.scenelist=this.Overview[c]
        }else {
          this.sonlinenumber=1
          let c=this.sonlinenumber-1
          this.sonlinewidth=this.sonline[c].sonlistwidth+1
          this.scenelist=this.Overview[c]
        }
      },2000)
    },
    tow(){
      this.yingyongweblist=setInterval(()=>{
        // this.sonline=this.sonline+1
        if(this.imagelistbox!=4){
           this.imagelistbox++
          //  console.log(this.imagelistbox)
        }else {
          this.imagelistbox=0

        }
      },2000)
    }
  },
  mounted(){
    this.one()
    this.tow()
  }
}
</script>
<style lang="less" scoped>
.trade {
  width: 100vw;
}
.sendlist {
  width: 1200px;
  margin: 0 auto;
  .contenttrade {
    width: 1200px;
    height: 140px;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.08);
    border-radius: 6px 6px 6px 6px;
    display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    // align-items: flex-start;
    .soncontent {
      line-height: 140px;
      height: 100%;
      width: 300px;
      text-align: center;
      // border-bottom: 4px solid #6352F9;
      font-size: 24px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      cursor:pointer;
      .bordersen {
        width: 300px;
        height: 4px;
        background: #6352F9;
      }
      .sendweb {
        width: 100%;
        height: 100%;
        border-bottom: #6352F9 solid 4px;
      }
      .sendwebtow {
        width: 100%;
        height: 100%;
      }
      .sendwebtow::after{
        content: "";
        border-bottom: #6352F9 solid 4px;
        display: block;
        transform: scale(0);
        transition:all 0.5s ease-out 0s; 
      }
      .sendwebtow:hover::after{
        transform: scale(1);
      }
    }
    
    
  }
  .content {
    margin-top: 100px;
    // margin-left: 126px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .contettitle {
      width: 496px;
      height: 504px;
      background-color: #FFFFFF;
      box-shadow: 2px 2px 16px 0px rgba(0,0,0,0.08);
      .title {
        padding: 50px 25px 40px 25px;
        font-size: 32px;
        font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
        font-weight: bold;
        color: #6352F9;
        line-height: 32px;
        text-align: center;
      }
      .titleson {
        margin: 0 25px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 21px;
        .onelist {
          margin-bottom: 25px;
          line-height: 29px;
        }
      }
    }
    .arrowhead {
      width: 227px;
      position: relative;
      .oblong {
        position: absolute;
        top: 226px;
        left: 41px;
        width: 78.37px;
        height: 23.95px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .triangle{
        width: 73px;
        height: 73px;
        position: absolute;
        top: 203.5px;
        right: 52px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .contettitles {
      width: 496px;
      height: 504px;
      // background-color: #FFFFFF;
      // box-shadow: 2px 2px 16px 0px rgba(0,0,0,0.08);
      background-image: url(../../../../image/Rectangle\ 6.png);
      background-size: 100% 100%;
      position: relative;
      .title {
        padding: 50px 25px 40px 25px;
        font-size: 32px;
        font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
        font-weight: bold;
        color: #6352F9;
        line-height: 32px;
        text-align: center;
      }
      .titleson {
        margin: 0 25px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 21px;
        .onelist {
          margin-bottom: 25px;
          line-height: 29px;
        }
      }
      .backimage {
        position: absolute;
        width: 162.82px;
        height: 158px;
        bottom: 6px;
        right: 16px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  // 功能特色
  .characteristic {
    margin-top: 96px;
    text-align: center;
    font-size: 48px;
    font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
    font-weight: bold;
    color: #000000;
  }
  .introduce {
    font-size: 28px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-top: 30px ;
    text-align: center;
  }
  .imagechaarcter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 61px;
    .image {
      position: relative;
      .listimageweb {
        width: 100%;
        height: 100%;
        border-radius: 6px 6px 6px 6px;
        overflow: hidden;
        position: absolute;
        border-radius: 6px 6px 6px 6px;
        top: 0;
        left: 0;
        img {
          width: 466px;
          height: 370px;
        }
      }
      .listtodo {
        position: absolute;
        border-radius: 6px 6px 6px 6px;
        overflow: hidden;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(-315deg, #10009A -53%, rgba(255, 255, 255, 0.3) 100%);
        .poread {
          position: absolute;
          top: 153px;
          left: 26px;
          font-size: 28px;
          font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
          font-weight: bold;
          color: #FFFFFF;
          transition: top 0.5s;
        }
        .poreads {
          position: absolute;
          top: 314px;
          left: 26px;
          transition: top 0.5s;
          font-size: 28px;
          font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
          font-weight: bold;
          color: #FFFFFF;          
        }
        .sendtow {
          position: absolute;
          left: 26px;
          bottom: 31px;
          width: 383px;
          height: 120px;
          font-size: 24px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          transition: bottom 0.5s;

        }
        .sendtows {
          position: absolute;
          left: 26px;
          bottom: -151px;
          // transition: bottom 0.5s;
          width: 383px;
          height: 120px;
          font-size: 24px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
      .onelist {
        width: 466px;
        height: 370px;
        background: linear-gradient(315deg, #10009A 2%, rgba(255,255,255,0) 100%);
        border-radius: 6px;
        transition: width 0.5s, height 0.5s;
      }
      .towlist {
        width: 164px;
        height: 370px;
        overflow: hidden;
        border-radius: 6px;
        background: linear-gradient(315deg, #10009A 0%, rgba(255,255,255,0) 100%);
       
      }
      // .towlist:hover {
      //   width: 466px;
      //   height: 370px;
      // }
      // .towlist:hover .sendtows{
      //   bottom: 31px;
      // }
      // .towlist:hover .poreads{
      //   top: 153px;
      // }
    }
  }
  // /应用
  .stepsend {
    margin-top: 63px;
    .widstep {
      width: 1100px;
      margin: 0 auto;
      .titlesend {
        z-index: 99;
        margin-left: 83px;
        margin-right: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        .sendpad {
          width: 180px;
          font-size: 30px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #6352F9;
        }
        // 
      }
      .line {
        z-index: 99;
        margin-top: 32px;
        margin-bottom: 30px;
        // display: flex;
        position: relative;
        .oneceshi {
          width: 1100px;
          height: 2px;
          margin: 0 auto;
          background: rgba(0, 0, 0, 0.2);
          .sonwidth {
            // width: 131px;
            height: 2px;
            background: #6352F9;
            transition: width 1s;
          }
          .listweb {
            width: 1100px;
            height: 2px;
            position: absolute;
            top: 0;
            left: 0;
            .cilcer {
              position: absolute;
              top: -7px;
              width: 12px;
              height: 12px;
              background: #6352F9;
              // background-color: #fff;
              border: 2px solid #6352F9;
              z-index: 99;
              border-radius: 8px;
              transition: border 1.5s, background 1.5s;
            }
            .cilcers {
              top: -7px;
              position: absolute;
              width: 12px;
              height: 12px;
              // background: rgba(0, 0, 0, 0.2);
              background-color: #fff;
              border-radius: 8px;
              // opacity: 0.2;
              z-index: 99;

              border: 2px solid hsla(0, 0, 0, 0.2);
            }
          }
        }
        .send {
          // width: 100%;
          display: flex;
          position: relative;
        }
        .gotoweb {
          width: 131px;
          height: 2px;
          background: #6352F9;
          border-radius: 0px 0px 0px 0px;
        }
        .gotowebtow {
          width: 254px;
          height: 2px;
          background: rgba(0, 0, 0, 0.2);
          // opacity: ;
        }
        .cil {
          position: relative;
        }
        // .cilcer {
        //   position: absolute;
        //   top: -7px;
        //   width: 12px;
        //   height: 12px;
        //   background: #6352F9;
        //   border: 2px solid #6352F9;
        //   border-radius: 8px;
        // }
        // .cilcers {
        //   position: absolute;
        //   top: -7px;
        //   width: 12px;
        //   height: 12px;
        //   opacity: 0.2;
        //   border: 2px solid #000;
        //   border-radius: 8px;
        // }
        
      }
      .number {
        z-index: 99;
        display: flex;
        .sendpadlist {
          // width: 170px;
          // text-align: right;
          position: relative;
          display: flex;
        }
        .wadlist {
          width: 18px;
        }
        .opsend {
          font-size: 48px;
          font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
          font-weight: 300;
          color: #6352F9;
          line-height: 48px;
          text-align: right;
        }
      }
      .titlesends {
          text-align: center;
        }
    }
  }
  // 应用内容
  .charaimage {
    margin-top: 60px;
    display: flex;
    .charaterimage {
      width: 426.7px;
      height: 240.72px;
      position: relative;
      .chartwebcontent {
        position: absolute;
        top: 0;
        left: 0;
      }
      .imageone {
        width: 426.7px;
        height: 240.72px;
        position: absolute;
        top: 0;
        left: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .imagetow {
        width: 449.24px;
        height: 253.43px;
        position: absolute;
        top: 42px;
        left: 37px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .imagethree {
        width: 472.49px;
        height: 266.79px;
        position: absolute;
        top: 87px;
        left: 88px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .charatertitle {
      margin-left: 335px;
      .contenttitle {
        font-size: 36px;
        font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
        font-weight: bold;
        color: #000000;
        margin-bottom: 20px;
      }
      .contentlist {
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-bottom: 40px;
      }
      .contentspan {
        font-size: 24px;
        font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
        font-weight: bold;
        color: #000000;
        margin-bottom: 33px;
      }
    }
  }
  // 合作伙伴
  .cooperate {
    width: 1200px;
    // height: 639.11px;
    margin: 0 auto;
    
    .hezuoflex {
      width: 1200px;
    // height: 639.11px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    }
    .imagebacklistweb {
      width: 224px;
      height: 111px;
      // border: 1px solid #ccc;
      margin-top: 20px;
      // transform: ;
      // padding: 10px;
      // border-radius: ;

      // transition: all .5s ease .1s;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.6s;
      }
      img:hover {
        transform: scale(1.1);
      }
    }
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 2),
screen and (min--moz-device-pixel-ratio: 2) {
  .contettitles {
    background-image: url('../../../../image/Rectangle\ 6@2x.png');
  }
}
// /deep/ .el-step__main {
//   position: relative;
// }
// /deep/.el-step__title {
//   position: absolute;
//   top: -80px;
// }
</style>
