<template>
  <div>
    <div class="sendlist">
      <div class="width">
        <div class="title">产品案例</div>
        <div class="spantile">期待您的加入给我们带来更多精彩</div>
        <div class="bottomweb" >
          <div v-for="(item,index) in list" :key="index" @click="sendto(index)">
            <div class="onelistweb" v-if="index==send">{{ item.title }}</div>
            <div class="towlistweb" v-else>{{ item.title }}</div>
          </div>
         
          
        </div>
      </div>
      <div class="imagelist">
        <div class="imglistone" v-for="(item,index) in sen" :key="index">
          <div class="image"><img :src="item.image" alt=""></div>
          <div class="titleimage">{{ item.title }}</div>
        </div>
       
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      list:[
        {id:0,title:'医疗直播'},
        {id:1,title:'线上云展厅'},
        {id:0,title:'视频制作'},
        {id:0,title:'微官网定制'},
      ],
      sen:[],
      // 展示
      zhanshi:[
        {id:0,title:'GE@CMEF',image:require('../../../../image/客户案例_slices/1.1.jpg')},
        {id:1,title:'ISUOG中国大会',image:require('../../../../image/客户案例_slices/1.2.jpg')},
        {id:2,title:'安贞医院母胎医学心脏相关疾病系类专题',image:require('../../../../image/客户案例_slices/1.3.jpg')},
        {id:3,title:'瓦里安超弧济世大会',image:require('../../../../image/客户案例_slices/1.4.jpg')},
        {id:4,title:'伯乐精准控制系列直播',image:require('../../../../image/客户案例_slices/1.5.jpg')},
        {id:5,title:'豪洛捷保乳面面观系列',image:require('../../../../image/客户案例_slices/1.6.jpg')},
        {id:6,title:'中国CT好技师系列比赛',image:require('../../../../image/客户案例_slices/1.7.jpg')},
        {id:7,title:' 数坤AI新品全球首发',image:require('../../../../image/客户案例_slices/1.8.jpg')},
      ],
      yunzhan:[
        {id:0,title:'云展厅1',image:require('../../../../image/客户案例_slices/2.1.jpg')},
        {id:1,title:'云展厅2',image:require('../../../../image/客户案例_slices/2.2.jpg')},
        {id:2,title:'云展厅3',image:require('../../../../image/客户案例_slices/2.3.jpg')},
        {id:3,title:'云展厅4',image:require('../../../../image/客户案例_slices/2.4.jpg')},
      ],
      videozhizuo:[
        {id:0,title:'脑健康规范化操作应用',image:require('../../../../image/客户案例_slices/3.1.jpg')},
        {id:1,title:'保妥适注射培训视频',image:require('../../../../image/客户案例_slices/3.2.jpg')},
        {id:3,title:'豪洛捷乳管镜操作视频',image:require('../../../../image/客户案例_slices/3.3.jpg')},
        {id:3,title:'GE医疗超声操作视频',image:require('../../../../image/客户案例_slices/3.4.jpg')},
      ],
      weiguanwang:[
        {id:0,title:'第十八届产前监测与妇科超声学术交流会',image:require('../../../../image/客户案例_slices/4.1.jpg')},
        {id:1,title:'一妇婴超声论坛',image:require('../../../../image/客户案例_slices/4.2.jpg')},
        {id:4,title:'《CALEEN妇产科超声学》系列培训',image:require('../../../../image/客户案例_slices/4.3.jpg')},
        {id:3,title:'乳腺医师X线专题培训',image:require('../../../../image/客户案例_slices/4.4.jpg')},
      ],
      send:0
    }
  },
  methods:{
    sendto(e){
      this.send=e
      if(e==0){
        this.sen=this.zhanshi
      }else if(e==1){
        this.sen=this.yunzhan
      }else if(e==2){
        this.sen=this.videozhizuo
      }else if(e==3){
        this.sen=this.weiguanwang
      }
      // if(this.sen==this.zhanshi){
      //   this.sen=this.zhanshis
      // }else {
      //   this.sen=this.zhanshi
      // }
    }
  },
  created(){
    this.sen=this.zhanshi
  }
}
</script>
<style lang="less" scoped>
.sendlist {
  width: 100vw;
  min-width: 1690px;
  margin: 0 auto;
  margin-top: 98px;
  .width {
    width: 1200px;
    margin: 0 auto;
  }
  .title {
    width: 176px;
    height: 44px;
    font-size: 44px;
    font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
    font-weight: bold;
    color: #000000;
  }
  .spantile{
    width: 420px;
    height: 28px;
    font-size: 28px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-top: 30px;
  }
  .bottomweb {
    margin-top: 60px;
    display: flex;
    .onelistweb {
      width: 132px;
      height: 52px;
      margin-right: 95px;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      border: 1px solid #6352F9;
      color: #6352F9;
      font-size: 20px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      text-align: center;
      line-height: 52px;
    }
    .towlistweb {
      width: 132px;
      height: 52px;
      margin-right: 95px;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      border: 1px solid #999999;
      color: #000;
      font-size: 20px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      text-align: center;
      line-height: 52px;
    }
  }
  .imagelist {
    width: 1690px;
    margin: 0 auto;
    margin-top: 61px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    .imglistone {
      width: 400px;
      height: 322px;
      border-radius: 6px;
      background: #FFFFFF;
      margin-bottom: 32px;
      // margin: 0;
      box-shadow: 2px 2px 12px 0px rgba(16,16,16,0.08);
      transform: translateY(0);
      transition: transform 0.5s;
      .image {
        width: 400px;
        height: 196.75px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 6px 6px 0 0;
        }
      }
    }
    .imglistone:hover{
      transform: translateY(-15px);
      background: #FFFFFF;
      box-shadow: 0px 4px 53px 0px rgba(0,0,0,0.08), 0px 0px 61px 0px rgba(0,0,0,0.08);
    }
    .titleimage {
      width: 340px;
      margin: 0 auto;
      font-size: 20px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      line-height: 32px;
      margin-top: 30px;
      margin-bottom: 47px;
    }
  }
}
</style>
