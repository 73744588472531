<template>
  <div>
    <div class="mackvideo">
      <div class="sendlist" id="Positions1">
        <div class="characteristic">功能特色</div>
        <div class="introduce">紧贴客户场景为客户提供丰富的直播体验</div>
        <div class="senweb">
          <div class="videomacklist" v-for="(item,index) in imageweb" :key="index">
            <div class="title">{{ item.title }}</div>
            <div class="titletow">{{ item.sontitle }}</div>
          </div>
         
        </div>
        <div class="characteristic">适用场景</div>
        <div class="introduce">满足客户多样化需求</div>
        <div class="videolunbo">
          <div class="video">
            <div class="sonvideo"><video :src="video" muted="" autoplay="" loop="" ></video></div>
            <div class="send">
              <div class="sonone"  v-for="(item,index) in videoimage" :key="index" @click="gotonumber(item,index)">
                <div class="onelist" v-if="index==spanwebnumber">{{ item.number }}</div>
                <div class="towlist" v-else>{{ item.number }}</div>
                <div class="weblist" v-if="index!=4"></div>
              </div>
            </div>
          </div>
          <div class="numberlist">
            <div class="number">{{ number }}</div>
            <div class="townumber">{{ townumber }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
 
  data(){
    return{
      sonwen:'',
      imageweb:[
        {id:0,title:'3D虚拟场景',sontitle:'提供多种虚拟场景模版，支持主流虚拟演播室系统，支持虚拟演播室场景建模。'},
        {id:1,title:'场景特效制作',sontitle:'设计精彩特效，完美融合3D场景，实现虚实相融的沉浸式直播效果。'},
        {id:2,title:'3D动画视频',sontitle:'3D建模与动画视频，可以根据客户需求为客户定制制作脚本。'},
        {id:3,title:'直播间搭建',sontitle:'支持客户工作场地、酒店等多种场景的绿幕搭建。'},
      ],
      videoimage:[
        {id:0,number:'01',titlenumber:'01 虚拟演播室',video:'https://alyvod.tongxianglive.cn/res/3d/3d4.mp4',townumber:'虚拟演播室视频拍摄和制作，支持实时合成和后期合成，支持跟踪摄像机、动作捕捉等技术。支持客户工作场地、酒店的绿幕搭建。支持主流虚拟演播室系统。支持虚拟演播室场景建模。支持3D特效的设计和制作。'},
        {id:1,number:'02',titlenumber:'02 产品宣讲视频',video:'https://alyvod.tongxianglive.cn/res/3d/product.mp4',townumber:'产品功能视频拍摄，产品使用培训视频拍摄，产品组装分解动画的设计和制作。支持产品建模以及3D动画。'},
        {id:2,number:'03',titlenumber:'03 3D动画视频',video:'https://alyvod.tongxianglive.cn/res/3d/3dcg.mp4',townumber:'3D建模与动画视频。可以根据需求为客户定制制作脚本。'},
        {id:3,number:'04',titlenumber:'04 会议视频',video:'https://wx1291e360dd0cf380.www.tongxianglive.cn/819202132/images/01MP401.mp4',townumber:'会议视频包括致辞视频、暖场视频、开场视频、串场视频、颁奖视频、提前预录制视频等等，范指会议中用到的素材和材料，页包含会议的全程录制视频、花絮视频、回顾视频等等。'},
        {id:4,number:'05',titlenumber:'05 培训视频',video:'https://alyvod.tongxianglive.cn/res/3d/training.mp4',townumber:'包含设备、系统、软件等使用培训视频，内部、外部培训视频，手术示教视频，等等各种培训相关的视频。'},
      ],
      spanwebnumber:0,
      number:'01 虚拟演播室',
      townumber:'虚拟演播室视频拍摄和制作，支持实时合成和后期合成，支持跟踪摄像机、动作捕捉等技术。支持客户工作场地、酒店的绿幕搭建。支持主流虚拟演播室系统。支持虚拟演播室场景建模。支持3D特效的设计和制作。',
      video:'https://alyvod.tongxianglive.cn/res/3d/3d4.mp4'
    }
  },
  props:{sonsentweb:Number},
  methods:{
    gotonumber(a,e){
      this.spanwebnumber=e
      this.number=a.titlenumber
      this.townumber=a.townumber
      this.video=a.video
    }
  },
  watch:{
    sonsentweb(){
        document .getElementById(`Positions1`) .scrollIntoView({ behavior: 'smooth' })
      
    }
  }
}
</script>
<style lang="less" scoped>
.mackvideo {
  width: 100vw;
  min-width: 1200px;
  background-color: #0C0A1D;
  height: 1624.9px;
  .sendlist {
    width: 1200px;
    margin: 0 auto;
    .characteristic {
      padding-top: 96px;
      text-align: center;
      font-size: 48px;
      font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
      font-weight: bold;
      color: #FFFFFF;
    }
    .introduce {
      font-size: 28px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-top: 30px ;
      text-align: center;
    }
    .senweb {
      margin-top: 60px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      .videomacklist {
        margin-bottom: 30px;
        width: 585px;
        height: 234px;
        border: 1px solid;
        background: linear-gradient(180deg, #101026 0%, #2C2938 100%);
        border-image: linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(255, 255, 255, 0.29)) 1 1;
        border-radius: 6px;

        // border-radius: 6px 6px 6px 6px;
        opacity: 1;
    
        
        .title {
          width: 516px;
          margin-left: 30px;
          margin-top: 29px;
          font-size: 40px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
        }
        .titletow {
          width: 516px;
          margin-left: 30px;
          margin-top: 30px;
          font-size: 24px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 38px;
        }
      }
      .videomacklist:hover{
        background: linear-gradient(180deg, #101026 0%, #2C2938 100%);
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        border: 1px solid;
        border-image: linear-gradient(180deg, rgba(99, 82, 249, 1), rgba(215, 89, 243, 1)) 1 1;
      }
    }
    .videolunbo {
      margin-top: 60px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 61px;
      .video {
        width: 759px;
        height: 430px;
        
        // background: #515151;
        border-radius: 0px 0px 0px 0px;
        position: relative;
        .send {
          width: 53px;
          height: 428px;
          position: absolute;
          // padding-top: 10px;
          top: 0;
          right: 0;
          background: linear-gradient(180deg, rgba(16,16,38,0.5) 0%, #2C2938 100%);
          .sonone{
            height: 88px;
            line-height: 88px;
            text-align: center;
            margin: 0 auto;
            width: 22px;
            
            position: relative;
            .onelist {
              width: 38px;
              position: absolute;
              top: 0;
              left: -6px;
              font-size: 32px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #FFFFFF;
              text-align: left;
            }
            .towlist {
              font-size: 20px;
              // border-top: 1px solid rgba(109,104,104,0.54);
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: rgba(255,255,255,0.37);
            }
            .weblist{
              position: absolute;
              bottom: 0;
              width: 19px;
              border: 1px solid rgba(109,104,104,0.54);
            }
          }
        }
        .sonvideo{
          width: 759px;
          height: 430px;
          video {
            width: 100%;
            height: 100%;
            border-radius: 6px;
          }
        }
      }
      .numberlist {
        width: 381px;
        .number {
          text-align: center;
          font-size: 44px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
        }
        .townumber {
          margin-top: 61px;
          font-size: 24px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 38px;
        }
      }
    }
  }
}
</style>
