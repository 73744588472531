<!--  -->
<template>
  <div class="listmoxing" ref="content">
    
  </div>
  
</template>

<script>
import * as Three from 'three'
// 导入轨道控制器
import {
  OrbitControls
} from 'three/examples/jsm/controls/OrbitControls'

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

// import {RGBELoader} from 'three/examples/jsm/loaders/RGBELoader'
export default {
  data () {
    return {
      scene:null,
      camera:null,
      renderer:null,
      mesh:null,
    };
  },
  methods:{
    init(){
      let containers = this.$refs.content.getElementsByTagName("canvas")
      console.log(containers.length)
      if(containers.length!=0){
        this.$refs.content.innerHTML = ""
      }
      let container = this.$refs.content
      console.log(container)
      this.camera = new Three.PerspectiveCamera(70, container.clientWidth/container.clientHeight, 0.01, 1000);
      // 相机的位置
      var vector = new Three.Vector3( 0, 0, - 1 );
      vector.applyQuaternion( this.camera.quaternion );
      // angle = vector.angleTo( target.position )
      // console.log(vector)
      this.camera.position.z = -150
      this.camera.position.y=0
      this.camera.position.x=0
      // console.log(vector,this.camera)
      // 场景
      this.scene = new Three.Scene()
       // 初始化渲染器
       this.renderer = new Three.WebGLRenderer({antialias:true});
      //  console.log(this.renderer)
      //  this.renderer.outputEncoding=Three.sRGBEncoding
      this.renderer.shadowMap.enabled = true; 
      // 渲染器的大小
      this.renderer.setSize(container.clientWidth,container.clientHeight);
      // 设置背景透明
      // 设置透明
      this.renderer.setClearAlpha(0.1)
      
      // 将渲染器添加到页面
      container.appendChild(this.renderer.domElement);
      var scene=this.scene
      // 加载模型
      this.loader = new GLTFLoader();
      let group=new Three.Group()
      this.loader.load( 'static/glb/zhanting.glb', function ( glb ) {
        console.log(glb)
        // glbs=glb.scence
        glb.scene.scale.set(10,10,10)
        glb.scene.position.y = -10;
        // glb.scene.position.x = 10;
        glb.scene.position.z = -50;

        // 展厅
        glb.scene.rotation.y=Math.PI*1
        glb.scene.rotation.x=Math.PI*1.99
        glb.scene.rotation.z=Math.PI*2
        // glb.scene.renderOrder=1
        // glb.scene.receiveShadow=true
        glb.scene.traverse( function ( child ) {
          // console.log(child)
          if ( child.isMesh ) {
              child.frustumCulled = false;
              //模型阴影
              child.castShadow = true;
              // console.log(child)
              //模型自发光
              child.material.emissive =  child.material.color;
              child.material.emissiveMap = child.material.map;
          }
        })
        group.add(glb.scene)
        scene.add(glb.scene);
        let i=0.05
          // setInterval(()=>{
          //   group.rotation.y+=0.01
          // },10)
          setInterval(()=>{
            glb.scene.rotation.y+=0.005
          },10)
      })

      var light1 = new Three.AmbientLight(0xffffff ,0.5);//绿
      light1.position.set(0,0,-90);
      scene.add(light1);

    //   // 方向光
      var light2 = new Three.SpotLight("#fff",0.5);//红
      light2.position.set(0,0,-90);
     scene.add(light2);
      var direction = new Three.DirectionalLight("#fff",0.9);
      // light1.position.set(0,0,0);
     scene.add(direction);
        // 创建轨道控制器 相机围绕模型看到的角度
        const OrbitControl = new OrbitControls(this.camera, this.renderer.domElement)
        OrbitControl.maxPolarAngle = 3;
        //上下翻转的最小角度
        OrbitControl.minPolarAngle =1.5;
        // // // // 水平旋转
        OrbitControl.minAzimuthAngle=-3.2;
        OrbitControl.maxAzimuthAngle=3.2
        // 是否允许缩放
        OrbitControl.enableZoom = false;

      // 设置轨道自然
      OrbitControl.enableDamping = true
      // 设置惯性
      OrbitControl.update()
      // document.addEventListener("mouseover",mouserover)

      this.renderer.setClearColor('#fff', 0);


    },
    animate(){
      // 浏览器自动渲染下一帧
      requestAnimationFrame(this.animate);
      // var animationMixer = new Three.AnimationMixer(scene);
      // 渲染到页面
      this.renderer.render(this.scene,this.camera); 
    //   if(animationMixer!==null){
    // //clock.getDelta()方法获得两帧的时间间隔
    // // 更新混合器相关的时间
    // animationMixer.update(clock.getDelta());
    },
    webapp(){
      // window.location.href='https://beian.miit.gov.cn/'
    },
    videoplay(){
      // const video = document.getElementById('video')
      // const video=document.getElementById('video')
      // video.play()
      // console.log(video)
    }
  },
  mounted(){
      this.init()
      this.animate()
  },
  created(){
    // this.videoplay()
    // this.init()
    // setTimeout(this.videoplay, 500)
  }

}

</script>
<style scoped>
.listmoxing{
    width: 600px;
    height: 400px;
}

</style>
