import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './views/phone/phonecss/index.css'

// element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

import Nutui from '@nutui/nutui';
import '@nutui/nutui/dist/nutui.css';

// Nutui.install(Vue);
Vue.use(Nutui);


// axios
import axios from 'axios';
   
Vue.prototype.$axios = axios


// import FastClick from 'fastclick'
// FastClick.attach(document.body);


// Vue.use(followswipe);
// vant
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
