<template>
  <div class="webclouds">
    <!-- 线上云展 -->
    <div class="sendlist">
      <div class="polist">
        <div class="contenttrade">
          <div class="soncontent"  v-for="(item,index) in trade" :key="index" @click="gotoson(index)">
            <div v-if="index==avent" class="sendweb">{{ item.title }}</div>
            <div v-if="index!=avent" class="sendwebtow">{{ item.title }}</div>
            <!-- <div  class="bordersen"></div> -->
          </div>
        </div>
      </div>
      <div class="imageyunzhans" v-for="(item,index) in imagetitle" :key="index" >
        <div class="imageyunzhan" v-if="index==avent">
          <div class="image">
            <img :src="item.image" alt="">
          </div>
          <div class="fontsize">
            <div class="title">{{ item.title }}</div>
            <div class="content">
              {{ item.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 背景图 -->
    <div class="backimage">
      <div style="width: 100vw;">
        <div class="titlesend">
          <div class="titleone">3D展厅——720°销售场景，不用到店也能体验</div>
          <div class="titletow">满足各行业多样的应用场景</div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="sendlist" id="Positions1">
      <div class="characteristic">客户案例</div>
      <div class="logolunbo">
        <iframe style="width: 100%;height: 100%;" src="https://yizhiyihui.cn/yizhidemo230410.html" frameborder="0"></iframe>
      </div>
      <div class="logolunbo">
        <iframe style="width: 100%;height: 100%;" src="https://wx1291e360dd0cf380.www.tongxianglive.cn/819202130/#/" frameborder="0"></iframe>
      </div>
      <div class="logolunbo">
        <iframe style="width: 100%;height: 100%;" src="https://wx1291e360dd0cf380.www.tongxianglive.cn/819202132/#/clounds" frameborder="0"></iframe>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      trade:[
        {id:0,title:'可漫游云展厅'},
        {id:0,title:'720全景云展厅'},
        {id:0,title:'产品3D展示'},
        {id:0,title:'虚拟数字人'},
      ],
      avent:0,
      imagetitle :[
        {id:0,title:'可漫游云展厅',content:'使用3D+Web技术，线上虚拟现实技术，通过移动、PC多渠道浏览展厅，不限时间地点，沟通无缝隙，打破距离与时间局限可以在3D云展厅中进行漫游。',image:require('../../../../image/云展厅/可漫游云展厅/manyou.jpg')},
        {id:1,title:'720全景云展厅',content:'使用720全景技术的3D云展厅，可以在多个热点的全景场景中漫游。',image:require('../../../../image/云展厅/720全景云展厅/2.2.jpg')},
        {id:2,title:'产品3D展示',content:'使用3D技术在网页、直播间、电子书等场景展示产品，支持引导式动画特效。',image:require('../../../../image/云展厅/产品3D展示/04b03.jpg')},
        {id:3,title:'虚拟数字人',content:'使用3D建模和动作捕捉等技术让数字人活灵活现，打造您的数字人IP。',image:require('../../../../image/云展厅/数字人/04c02.jpg')},
      ],
      lunboimage :[
        {id:0,image:require('../../../../image/线上云展厅_slices/企业展厅1.png')},
        {id:1,image:require('../../../../image/线上云展厅_slices/企业展厅1.png')},
        {id:2,image:require('../../../../image/线上云展厅_slices/企业展厅1.png')},
        // {id:3,image:require('../../../../image/线上云展厅_slices/企业展厅1.png')},
      ]
    }
  },
  methods:{
    gotoson(e){
      this.avent=e
      console.log(e)
    },
  }
}
</script>
<style lang="less" scoped>
.webclouds {
  max-width: 1920px;
  // width: 1920px;
  min-width: 1200px;
}
.sendlist {
  width: 1200px;
  margin: 0 auto;
  .polist {
    position: relative;
    width: 1200px;
    height: 140px;
  }
  .contenttrade {
    position: absolute;
    top: -70px;
    width: 1200px;
    height: 140px;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.08);
    border-radius: 6px 6px 6px 6px;
    display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    // align-items: flex-start;
    .soncontent {
      line-height: 140px;
      height: 100%;
      width: 300px;
      text-align: center;
      // border-bottom: 4px solid #6352F9;
      font-size: 24px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      cursor:pointer;
      .bordersen {
        width: 300px;
        height: 4px;
        background: #6352F9;
      }
      .sendweb {
        width: 100%;
        height: 100%;
        border-bottom: #6352F9 solid 4px;
      }
      .sendwebtow {
        width: 100%;
        height: 100%;
      }
      .sendwebtow::after{
        content: "";
        border-bottom: #6352F9 solid 4px;
        display: block;
        transform: scale(0);
        transition:all 0.5s ease-out 0s; 
      }
      .sendwebtow:hover::after{
        transform: scale(1);
      }
    }
    
    
  }
  .imageyunzhan {
    display: flex;
    .image {
      width: 478px;
      height: 300px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .fontsize {
      margin-left: 79px;
      width: 562px;
      .title {
        text-align: center;
        font-size: 44px;
        font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
        font-weight: bold;
        color: #000000;
      }
      .content {
        margin-top: 60px;
        font-size: 28px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 45px;
      }
    }
  }
  .characteristic {
    margin-top: 96px;
    text-align: center;
    font-size: 48px;
    font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
    font-weight: bold;
    color: #000000;
  }
}
// 
.backimage {
  width: 1920px;
  margin-top: 100px;
  height: 284px;
  background-image: url('../../../../image/线上云展厅_slices/888\ 1.png');
  background-size: 100% 100%;
  .titlesend {
    width: 1200px;
    margin: 0 auto;
    .titleone {
      font-size: 60px;
      padding-top: 81px;
      width: 1285px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 60px;
    }
    .titletow {
      font-size: 32px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      margin-top: 30px;
    }
  }
}
.logolunbo {
  width: 1200px;
  margin: 0 auto;
  height: 592px;
  margin-top: 60px;
  .imagesend {
    width: 880.93px;
    height: 592px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
// element

@media screen and (-webkit-min-device-pixel-ratio: 2),
screen and (min--moz-device-pixel-ratio: 2) {
  .backimage {
    background-image: url('../../../../image/线上云展厅_slices/888\ 1@2x.png');
  }
}
</style>
