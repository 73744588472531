<template>
  <div>
    <div class="component" >
      <!-- 导航 -->
      <div class="navson">
        <navs @child-msg="getChildMsg"></navs>
      </div>
      <!-- 首页 -->
      <div class="backimage" v-if="computernumber==0">
        <div class="sendlistthree">
          
          <div class="titlethree">
            <div class="titlesendlist">
              <div class="titleone">医知医汇</div>
              <div class="titletow">专业  稳定  流畅 卓越科技 专业  稳定  流畅 卓越科技 </div>
            </div>
            <video class="videolist" src="static/230407医知医汇/230407医知医汇logo.mp4" muted="" autoplay="" loop="" ></video>
            <div class="video" v-if="computernumber==0">
              <video :src="video" muted="" autoplay="" loop="" ></video>
            </div>
          </div>
        </div>
      </div>
       <!-- 线上云展厅 -->
       <div class="backimages" v-if="computernumber==3">
        <div class="sendlistthree">
          <!-- <video src=""></video> -->
          <div class="titlethree">
            <div class="titlesendlist">
              <div class="titleone">线上云展厅</div>
              <div class="titletow">DIGITAL GALLERY</div>
            </div>
            <div class="eventModel">
              <moxing></moxing>
            </div>
          </div>
        </div>
      </div>
       <!-- 微站定制 -->
      <div class="imagebackvideo" v-if="computernumber==1">
        <iframe style="width: 100%; height: 100%;" src="https://wx1291e360dd0cf380.www.tongxianglive.cn/819202135/#/" frameborder="0"></iframe>
      </div>
      <!-- 视频制作 -->
      <div class="imagebacktow" v-if="computernumber==2">
        <!-- <iframe style="width: 100%; height: 100%;" src="../../phone/component/videoquanjing" frameborder="0"></iframe> -->
        <videoquanjing style="width: 100%; height: 100%;"></videoquanjing>
        <div class="posti">
          <div class="image" @click="gotomackvideo">
            <img src="../../../image/视频制作_slices/向下滑动按钮@2x.png" alt="">
            
          </div>
        </div>
      </div>
      <!-- 客户案例 -->
      <div class="backimageke" v-if="computernumber==4">
        <div class="sendlistthree">
          <div class="titlethree">
            <div class="titlesendlist">
              <div class="titleone">医知医汇</div>
              <div class="titletow">专业  稳定  流畅 卓越科技 专业  稳定  流畅 卓越科技 </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 关于我们 -->
      <div class="contentmen" v-if="computernumber==5">       
      </div>
      <!-- 详细内容 -->
      <!-- 首页 -->
      <div class="content sendbottom" v-if="computernumber==0">
        <trade></trade>
      </div>
      <!-- /微官网定制 -->
      <div class="contents sendbottom" v-if="computernumber==1">
        <officate></officate>
      </div>
      <!-- 云展厅 -->
      <div class="contenttowlist sendbottom" v-if="computernumber==3">
        <clounds></clounds>
      </div>
      <!-- /视频制作 -->
      <div class="videolist" v-if="computernumber==2">
        <mackvideo :sonsentweb="videoweb"></mackvideo>
      </div>
      <!-- 客户案例 -->
      <div class="sendbottom" v-if="computernumber==4">
        <kehu></kehu>
      </div>
      <!-- 关于我们 -->
      <div class="sendbottom" v-if="computernumber==5">
        <women></women>
      </div>
      <!-- dibu  -->
      <div class="afterlist">
        <after></after>
      </div>
    </div>
    <!-- 页面咨询 -->
    <div class="zixun">
      <zixun></zixun>
    </div>
  </div>
</template>
<script>
import navs from '../../../components/computer/nav'
import after from '../../../components/computer/afterbody'
import videoquanjing from '../../../components/computer/videoquanjing'
import trade from '../component/length'
import clounds from '../component/clouds'
import mackvideo from '../component/mackvideo'
import kehu from '../component/kehu'
import officate from '../component/officate'
import women from '../component/women'
import zixun from '../component/fromzixun'
import moxing from '../component/moxing'
// let glbs=null;
export default {
  data(){
    return {
      video:'https://wx1291e360dd0cf380.www.tongxianglive.cn/819202132/images/01MP401.mp4',
      computernumber:0,
      videoweb:0,
      
    }
  },
  methods:{
    getChildMsg(e){
      // console.log(e)
      this.computernumber=e
    },
    gotomackvideo(){
      // 子页面传值向下滑动
      console.log('123')
      this.videoweb=this.videoweb+1
      // console.log(this.videomack)
    },
  
  },
  components:{
    navs,
    trade,
    clounds,
    after,
    mackvideo,
    kehu,
    officate,
    women,
    videoquanjing,
    zixun,
    moxing
  }
}
</script>
<style lang="less" scoped>
.component {
  max-width: 1920px;
  min-width: 1200px;
  overflow: hidden;
  margin: 0 auto;
  // 背景
  .backimage {
    margin-top: 14px;
    width: 2044.77px;
    height: 500px;
    background-image: url('../../../image/001.png');
    background-size: 100% 100%;
    .sendlistthree {
      width: 100vw;
      height: 100%;
      // position: relative;
      .videolist {
        position: absolute;
        top: 60px;
        right: 0;
        // width: ;
        mix-blend-mode: screen;
        width: 640px;
        height:360px;
      }
    }
    .titlethree {
      width: 1200px;
      margin: 0 auto;
      // margin-top: 113px;
      height: 100%;
      position: relative;
      .titlesendlist {
        position: absolute;
        top: 113px;
        .titleone {
          width: 261px;
          height: 81px;
          font-size: 64px;
          font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
          font-weight: bold;
          color: #FFFFFF;
        }
        .titletow {
          width: 599px;
          height: 80px;
          font-size: 32px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 40px;
          margin-top: 15px;
          // margin-left: 10px;
        }
      }
      .video {
        position: absolute;
        left: 203px;
        top: 400px;
        width: 718px;
        height: 404px;
        // background: #D9D9D9;
        video {
          width: 100%;
          height: 100%;
          border-radius: 6px;
        }
      }
    }
  }
  .backimages {
    margin-top: 14px;
    width: 2044.77px;
    height: 500px;
    background-image: url('../../../image/云展.png');
    background-size: 100% 100%;
    position: relative;
    .sendlistthree {
      width: 100vw;
      height: 100%;
      .cloudsweb {
       
        
        
      }
      .eventModel {
        position: absolute;
        top: 100px;
        right: 0;
          width: 640px;
          height: 440px;
        }
    }
    .titlethree {
      width: 1200px;
      margin: 0 auto;
      // margin-top: 113px;
      height: 100%;
      position: relative;
      .titlesendlist {
        position: absolute;
        top: 113px;
        .titleone {
          // width: 261px;
          height: 81px;
          font-size: 64px;
          font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
          font-weight: bold;
          color: #FFFFFF;
        }
        .titletow {
          width: 599px;
          height: 80px;
          font-size: 32px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 40px;
          margin-top: 15px;
          // margin-left: 10px;
        }
      }
      .video {
        position: absolute;
        left: 203px;
        top: 400px;
        width: 731px;
        height: 404px;
        // background: #D9D9D9;
        video {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .imagebacktow {
    width: 1925.09px;
    height: 933.9px;
    margin-top: 14px;
    background-image: url('../../../image/视频制作_slices/04b02\ 1.png');
    background-size: 100% 100%;
    position: relative;
    .posti {
      width: 100vw;
      min-width: 1200px;
      height: 262px;
      position: absolute;
      bottom: 0;
      background-image: url('../../../image/视频制作_slices/黑色遮罩@2x.png');
      background-size: 100% 100%;
      // margin: 0 auto;
      .image {
        margin: 0 auto;
        margin-top: 57px;
        width: 44px;
        height: 94.5px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  // 关于
  .contentmen {
    width: 2001.47px;
    height: 496.03px;
    margin-top: 14px;
    background-image: url('../../../image/关于我们_slices/头图部分@2x.png');
    background-size: 100% 100%;
  }
  .imagebackvideo {
    width: 1600px;
    height: 900px;
    margin: 0 auto;
    margin-top: 14px;
  }
  .content {
    margin-top:  404px;
  }
  .contenttowlist {
    // height: 600px;
  }
  .sendbottom {
    margin-bottom: 98px;
  }
  .afterlist {
    // margin-top: 98px;
  }
  // 可
  .backimageke {
    margin-top: 14px;
    width: 1920px;
    height: 501.79px;
    background-image: url('../../../image/客户案例_slices/相机\ 1.png');
    background-size: 100% 100%;
    .sendlistthree {
      width: 100vw;
      height: 100%;
    }
    .titlethree {
      width: 1200px;
      margin: 0 auto;
      // margin-top: 113px;
      height: 100%;
      position: relative;
      .titlesendlist {
        position: absolute;
        top: 113px;
        .titleone {
          width: 261px;
          height: 81px;
          font-size: 64px;
          font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
          font-weight: bold;
          color: #FFFFFF;
        }
        .titletow {
          width: 599px;
          height: 80px;
          font-size: 32px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 40px;
          margin-top: 15px;
          // margin-left: 10px;
        }
      }
      .video {
        position: absolute;
        left: 203px;
        top: 400px;
        width: 731px;
        height: 404px;
        // background: #D9D9D9;
        video {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 2),
screen and (min--moz-device-pixel-ratio: 2) {
  .backimage {
    background-image: url('../../../image/001.png');
  }
  .backimageke {
    background-image: url('../../../image/客户案例_slices/相机\ 1@2x.png');
  }
  .imagebacktow {
    background-image: url('../../../image/视频制作_slices/04b02\ 1@2x.png');
  }
}
</style>
