<template>
  <div>
    <!-- 微官网 -->
    <div class="officel">
      <div class="sendlist" id="Positions1">
        <div class="characteristic">微官网开发制作</div>
        <div class="introduce">微网站六大优势，立体展示品牌</div>
      </div>
      <div class="contentphone">
        <div class="flexphone" id="overflow">
          <div class="sonimage"><img src="../../../../image/image 11_slices/image 11@2x.png" alt=""></div>
          <div class="sendweb">滑动查看</div>
        </div>
        <div class="title">
          <div class="sontitle">微网站跨平台展示，节省开发成本</div>
          <div class="sontitle">微活动微服务，提高用户粘性</div>
          <div class="sontitle">微网站内容更新简单、网站维护便捷</div>
          <div class="sontitle">微官网功能便捷，体验流畅</div>
          <div class="sontitle">精美导航，清晰构架，浏览更流畅</div>
          <div class="sontitle">微官网，轻便快捷，利于营销</div>
        </div>
        <div class="flexphones">
          <img src="../../../../image/image 11_slices/2011680070283_ 1@2x.png" alt="" class="image">
        </div>
      </div>
      <div class="sendlist" id="Positions2">
        <div class="characteristic">客户案例</div>
        <div class="introduce">提供给客户多样化选择</div>
      </div>
      <div class="code">
        <div class="send">
          <div id="qrcode">
            <img src="../../../../image/image 11_slices/01.png" alt="">
          </div>
          <div class="qrcodetitle">扫码进入案例1</div>
        </div>
        <div class="send">
          <div id="qrcode">
            <img src="../../../../image/image 11_slices/02.png" alt="">
          </div>
          <div class="qrcodetitle">扫码进入案例2</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import QRCode from "qrcodejs2";
export default {
  data(){
    return{}
  },
  methods:{
   
  },
  created(){
    // setTimeout(this.image, 500)
  },
  mounted(){
    var message = document.getElementById('overflow');
    // message.scrollTop = message.scrollHeight;
    setInterval(()=>{
      message.scrollTop++; //在定时器中利用滚动scrollTop的增加达到滚动效果
      if(message.scrollTop==2651){
        // console.log(message.scrollTop)
        message.scrollTop=0;
      }
    },15)
  }
}
</script>
<style lang="less" scoped>
.officel {
  width: 1603px;
  margin: 0 auto;
}
.sendlist {
  width: 1200px;
  margin: 0 auto;
  .characteristic {
    margin-top: 96px;
    text-align: center;
    font-size: 48px;
    font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
    font-weight: bold;
    color: #000000;
  }
  .introduce {
    font-size: 28px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-top: 30px ;
    text-align: center;
  }
}
.contentphone {
  width: 1603px;
  margin: 0 auto;
  margin-top: 65px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .flexphone {
    width: 411px;
    height: 801px;
    position: relative;
    background: #F5F5F5;
    box-shadow: 0px -16px 28px 6px #FFFFFF, 30px 30px 48px -9px rgba(0,0,0,0.17);
    border-radius: 60px 60px 60px 60px;
    opacity: 1;
    border: 5px solid #000000;
    overflow: scroll;
    scrollbar-width: none; /* firefox */
   -ms-overflow-style: none; /* IE 10+ */
    .sonimage {
      height: 3449.47px;
      width: 415px;
      margin-left: -1px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .sendweb {
      position: absolute;
      bottom: 50%;
      width: 150px;
      // height: 50px;
      font-size: 18px;
      color: #FFFFFF;
      left: 50%;
      transform: translate(-50%,50%);
      text-align: center;
      // border: 1px solid #fff;
      background-color: rgba(0,0,0,0.5);
      padding: 5px;
      border-radius: 10px;
    }
    .image {
      width: 415px;
      height: 808px;
    }
  }
  .flexphone:hover .sendweb{
    display: none;
  }
  .flexphones {
    width: 411px;
    height: 801px;
    background: #F5F5F5;
    box-shadow: 0px -16px 28px 6px #FFFFFF, 30px 30px 48px -9px rgba(0,0,0,0.17);
    border-radius: 60px 60px 60px 60px;
    opacity: 1;
    border: 5px solid #000000;
   
    .image {
      width: 412px;
      height: 801px;
    }
  }
  .flexphone::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}
  .title {
    margin-top: 145px;
    width: 512px;
    height: 532px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    .sontitle {
      margin-bottom: 46px;
      text-align: center;
      font-size: 32px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #000000;
// line-height: 38px;
    }
  }
}
.code {
  width: 1200px;
  margin: 0 auto;
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  #qrcode {
    width: 300px;
    height: 300px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  // #qrcodes {
  //   width: 300px;
  //   height: 300px;
  // }
  .qrcodetitle {
    width: 300px;
    text-align: center;
    margin-top: 28px;
    font-size: 24px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
}
</style>
