<template>
  <div class="navlist">
    <!-- 导航 -->
     <div class="navsend">
      <div class="leftsend">
        <div class="logo"> <img src="../../../image/矢量logo1.png"  srcset="../../../image/矢量logo1@2x.png  2x,"  alt=""></div>
        <div class="menu">
          <div class="muend" v-for="(item,index) in muend" :key="index" @click="handleSelect(index)">
            <span v-if="index==activeIndex" style="color: #6352F9;">{{ item.title }}</span>
            <span v-else style="color: #000;">{{ item.title }}</span>
          </div>
          <div class="phonenumber">
            <div class="imagephone"><img src="../../../image/dianhua1.png"  srcset="../../../image/dianhua1@2x.png  2x," alt=""></div>
            <div class="phonelist">010-67760432</div>
          </div>
        </div>
      </div>
      <div class="postson" v-if="sonlist">
        <div class="titleson" >
          <div class="titlelist" v-for="(item,index) in sontitle" :key="index" @click="sontitleweb(index)">
            <span v-if="index==activeIndexs" style="color: #6352F9;">{{ item.title }}</span>
            <span v-else style="color: #000;">{{ item.title }}</span></div>
          </div>
        </div>
     </div>
    
  </div>
</template>
<script>
export default {
  data(){
    return{
      activeIndex:0,
      muend:[
      {id:0,title:'医疗直播',color:'#6352F9'},
        {id:1,title:'微官网定制',color:'#6352F9'},
        {id:2,title:'视频制作',color:'#6352F9'},
        {id:3,title:'线上云展厅',color:'#6352F9'},
        {id:4,title:'客户案例',color:'#6352F9'},
        {id:5,title:'关于我们',color:'#6352F9'},
      ],
      activeIndexs:0,
      sontitle:[
        {id:0,title:'医疗直播',color:'#6352F9'},
        {id:1,title:'微官网定制',color:'#6352F9'},
        {id:2,title:'视频制作',color:'#6352F9'},
        {id:3,title:'线上云展厅',color:'#6352F9'},
      ],
      sonlist:false
    }
  },
  methods:{
    handleSelect(keyPath){
      // console.log( keyPath)
      // if(keyPath==1){
      //   this.$emit('child-msg', 5)
      // }
      // if(keyPath==2){
        this.$emit('child-msg', keyPath)
      // }
      
      this.activeIndex=keyPath
      // if(keyPath!=0){
      //   this.activeIndexs=4
      //   this.sonlist=false
      // }else {
      //   this.sonlist=!this.sonlist
      // }
    },
    
  }
}
</script>
<style lang="less" scoped>
.navlist {
  width: 100vw;
  cursor:pointer;
  // width: 1920px;
  // margin: 0 auto;
  // z-index: 99;
}
.navsend {
  width: 1200px;
  margin: 0 auto;
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  .leftsend {
    display: flex;
    .logo{
      width: 154px;
      height: 49.94px;
      img {
        padding: 4px 5px 0 0;
        width: 100%;
        height: 100%;
      }
    }
    .menu {
      font-family: Microsoft YaHei-Regular, Microsoft YaHei !important;
      font-weight: 600 !important;
      margin-left: 40px;
      font-size: 19px;
      display: flex;
      .muend {
        height: 53.94px;
        line-height: 53.94px;
        margin-right: 40px;
      }
      .phonenumber {
        display: flex;
        height: 53.94px;
        line-height: 53.94px;
        .imagephone {
          width: 32px;
          height: 32px;
          margin-top: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .login {
    margin-top: 6px;
    width: 96px;
    height: 48px;
    font-size: 20px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    background: #6352F9;
    border-radius: 6px 6px 6px 6px;
    line-height: 48px;
    text-align: center;
  }
}
.postson {
  position: absolute;

  left: 159px;
  top: 68px;
  width: 160px;
  height: 201px;
  border-radius: 6px 6px 6px 6px ;
  box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.08);
  background: #fff;
  z-index: 99;
  .titleson {
    width: 90px;
    height: 141px;
    margin: 30px 40px;
    border: 1px;
    .titlelist {
      margin-bottom: 15px;
      font-size: 17px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 600;
    }
  }
}
// element 


</style>
