<template>
  <div>
    <!-- 关于我们 -->
    <div class="contentlist">
      <!-- <div class="image">
        <img src="../../../../image/关于我们_slices/image 13.png" alt="">
      </div> -->
      <div class="title">
        <div class="titleone">公司介绍</div>
        <div class="titletow">紧贴客户场景为客户提供分别丰富的直播体验</div>
      </div>
      <div class="weblicheng">
        <div class="titleyear">
          <div  v-for="(item,index) in imagebackweb" :key="index" @click="sonlist(item,index)">
            <div class="listapptow" v-if="index==appweblist">{{ item.yearlist }}</div>
            <div class="listapp" v-else>{{ item.yearlist }}</div>
          </div>
        </div>
      </div>
      <div class="imagebackweblist">
        <div class="backweblist"></div>
        <!-- <div class="titlewebimage">蓬勃发展</div> -->
        <div class="sonlist">
          <div class="yearsonimage" v-for="(item,index) in soncontentlist" :key="index">
            <div class="border">
              <div class="listborder"></div>
              <div class="titleweblistone">{{ item.title }}</div>
            </div>
            <div class="weblistapp"></div>
          </div>

        </div>
        
       
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      imagebackweb:[
      {id:0,yearlist:'2020',title:'蓬勃发展',soncontent:[
          {id:0,title:'11月：公司成立'},
         
        ]},
        {id:0,yearlist:'2021',title:'蓬勃发展',soncontent:[
          {id:0,title:'4月：发布医知医汇直播CMS系统'},
          {id:1,title:'5月：“超声e站”公众号开始正式运营'},
          {id:2,title:'7月：承办安贞医院母胎医学心脏相关疾病系类专题'},
        ]},
        {id:0,yearlist:'2022',title:'蓬勃发展',soncontent:[
        {id:0,title:'2月：“超声e站”粉丝超过1万'},
          {id:0,title:'6月：《Callen妇产科超声学》公益活动启动'},
          {id:0,title:'11月：“超声e站”粉丝超过2万'},
          
        ]},
        {id:0,yearlist:'2023',title:'蓬勃发展',soncontent:[
          {id:0,title:'3月：医知医汇网 medexpo.net 启动'},
        
        ]},
      ],
      appweblist :0,
      soncontentlist:[
        {id:0,title:'11月：公司成立'},
      ]
    }
  },
  methods:{
    sonlist(e,a){
      this.appweblist=a
      this.soncontentlist=e.soncontent
    }
  }
}
</script>
<style lang="less" scoped>
.contentlist {

  width: 1200px;
  margin: 0 auto;
  // display: flex;
  margin-top: 101px;
  
  .title {
    // width: 310px;
    margin-top: 101px;
    margin-left: 116px;
    text-align: center;
    .titleone {
      font-size: 48px;
      font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
      font-weight: bold;
      color: #000000;
      text-align: center;
    }
    .titletow {
      font-size: 28px;
      margin-top: 54px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 45px;
    }
  }
  .weblicheng {
    width: 1200px;
    margin: 0 auto;
    margin-top: 62px;
    .titleyear {
      width: 1070px;
      margin: 0 auto;
      height: 110px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      .listapp {
        width: 84px;
        height: 34px;
        font-size: 34px;
        font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
        font-weight: bold;
        color: #6F7279;
        margin-top: 77px;
      }
      .listapptow {
        font-size: 110px;
        font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
        font-weight: bold;
        color: #DBDFEC;
      }
    }
  }
  .imagebackweblist {
    width: 1200px;
    margin: 0 auto;
    margin-top: 59px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    .backweblist {
      width: 285.19px;
      height: 212.13px;
      background-image: url('../../../../phoneimage/55关于我们_slices/image\ 13@2x.png');
      background-size: 100% 100%;
    }
    .titlewebimage {
      width: 96px;
      height: 24px;
      font-size: 24px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
    }
    // .sonlist {
    //   dis
    // }
    .yearsonimage {
      // display: flex;
      .border {
        display: flex;
        .listborder {
          width: 24px;
          height: 24px;
          background: #FFFFFF;
          opacity: 1;
          border: 3px solid #6352F9;
          border-radius: 15px;
        }
        .titleweblistone {
          // width: 356px;
          height: 24px;
          font-size: 24px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          line-height: 30px;
          margin-left: 15px;
        }
        
      }
      .weblistapp {
          height: 42px;
          width: 3px;
          background-color: #D9D9D9;
          margin-left: 13px;
        }
    }
  }
}
</style>
