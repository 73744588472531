<!--  -->
<template>
  <div id="container">
    <div class="videoimage" @click="videoplay">
    </div>
    <video style="display: none;"  x-webkit-airplay="true" 
          playsinline="true"  
          x5-video-player-type="h5"
          x5-video-orientation="h5"
          x5-video-player-fullscreen="true"  id="video" controls loop muted>  <source src="static/video/4.mp4" /></video>
  </div>
  
</template>

<script>
import * as Three from 'three'
// 导入轨道控制器
import {
  OrbitControls
} from 'three/examples/jsm/controls/OrbitControls'
// import {RGBELoader} from 'three/examples/jsm/loaders/RGBELoader'
let scene = null,
camera=null,
renderer=null,
mesh=null
export default {
  data () {
    return {
        
    };
  },
  methods:{
    init(){
      let container = document.getElementById('container');

      // 添加相机
      camera = new Three.PerspectiveCamera(70, container.clientWidth/container.clientHeight, 0.01, 1000);
      // 相机的位置
      camera.position.z = 0.1

      // 场景
      scene = new Three.Scene()
        // 创建多边形

      // 创建球
      let geometry=new Three.SphereBufferGeometry(5,32,32)
      var video = document.getElementById('video') //获取video标签元素
      // let video = document.createElement('video');
      // video.src = "static/video/4.mp4"; // 设置视频地址
      // video.muted = "muted"; //要设置播放
      // video.look = "look"; //要设置播放
      // video.play()
      var texture = new Three.VideoTexture(video) //创建视频纹理
      console.log(video)
      // var texture = new THREE.VideoTexture(video);
      texture.wrapS = texture.wrapT = Three.ClampToEdgeWrapping;
      texture.minFilter = Three.LinearFilter;
      texture.needsUpdate = true;
      texture.updateMatrix();
      console.log(texture)
     
        
      let material = new Three.MeshBasicMaterial({map: texture });
      mesh = new Three.Mesh(geometry, material);
      mesh.geometry.scale(1, 1, -1);
      // mesh.rotateY=
      mesh.rotation.y=Math.PI*2.5
      scene.add(mesh);
      // 初始化渲染器
      renderer = new Three.WebGLRenderer({antialias:true});
      // 渲染器的大小
      renderer.setSize(container.clientWidth,container.clientHeight);
      // 将渲染器添加到页面
      container.appendChild(renderer.domElement);
      renderer.antialias=true;

      renderer.alpha=true;

      // renderer.precision='mediump' 



      // 创建轨道控制器 相机围绕模型看到的角度
      const OrbitControl = new OrbitControls(camera, renderer.domElement)
      // 设置轨道自然
      OrbitControl.enableDamping = true
      // 设置惯性
      OrbitControl.update()

        // // 是否允许缩放
        OrbitControl.enableZoom = false;
      // 设置轨道自然
      OrbitControl.enableDamping = true

    },
    animate(){
      // 浏览器自动渲染下一帧
      requestAnimationFrame(this.animate);
     
      // 渲染到页面
      renderer.render(scene,camera); 
    },
    webapp(){
      window.location.href='https://beian.miit.gov.cn/'
    },
    videoplay(){
      // const video = document.getElementById('video')
      const video=document.getElementById('video')
      video.play()
      console.log(video)
    }
  },
  mounted(){
      this.init()
      this.animate()
  },
  created(){
    // this.videoplay()
    setTimeout(this.videoplay, 500)
  }

}

</script>
<style scoped>
#container{
    width: 100vw;
    height: 100vh;
}
.listweb {
  position: fixed;
  top: 5px;
  left: 5px;
  font-size: 30px;
  font-weight: 900;
  color: aliceblue;
}
.listapp {
  position: fixed;
bottom: 5px;
/* right: 20%; */
text-align: center;
width: 100vw;
font-size: 16px;
color: red;
font-weight: 600;
}
.videoimage {
  position: absolute;
  bottom: 10px;
  left: 50px;
  color: #fff;
}
#video {
  /* width: 1px;
  height: 1px; */
  /* position: absolute; */
  top: 0;
}
</style>
