import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/computer/home'
import ceshi from '../views/computer/component/listmoxing'

// import phone from '../views/phone/homeview'

Vue.use(VueRouter)

const routes = [
  {
    // mode: "history", //去除#
    path: "/",
    name: "homepage",
    // component: homepage,
    components: {
      // default: () => import("@/views/pc/index.vue"),
      pc: () => import("../views/computer/home"),
      m: () => import("../views/phone/home"),
      // m: () => import("@/views/Home.vue"),
    },
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/ceshi',
    name: 'ceshi',
    component: ceshi
  },
 
]
const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
