<template>
  <div>
    <!-- 咨询 -->
    <div class="phonenumber">
      <div class="phonelist" @click="liuyanshows=!liuyanshows">
        <!-- <div class="backweb"></div> -->
        <div class="imagetitle">
          <div class="image">
            <img src="../../../../image/电话/Frame.png" srcset="../../../../image/电话/Frame@2x.png  2x," alt="">
          </div>
          <div class="title">电话咨询</div>
        </div>
        
      </div>
      <div class="phonelist"  @click="liuyanshow=!liuyanshow">
        <div class="imagetitle">
          <div class="image">
            <img src="../../../../image/电话/Vector.png" srcset="../../../../image/电话/Vector@2x.png" alt="">
          </div>
          <div class="title">申请留言</div>
        </div>
      </div>
    </div>
    <!-- 留言页面 -->
   <div class="showborder" v-if="liuyanshow">
    <div class="navlistimage">
      <div class="compostallwebx" @click="liuyanshow=false">+</div>
    </div>
    <div class="contitle">
      <div class="titlelist">医知医汇</div>
      <div class="sontitle">请留下你的联系方式，我们会尽快联系您！</div>
    </div>
    <div class="fromnlisr">
      <div class="inputweb">
        <div class="names">姓名</div>
        <div class="input">
          <input v-model="form.name" type="text" placeholder="请输入姓名" maxlength="20">
        </div>
      </div>
      <div class="inputweb">
        <div class="names">手机号</div>
        <div class="input">
          <input v-model="form.phonenumber" type="text" placeholder="请输入手机号" maxlength="20">
        </div>
      </div>
      <div class="inputweb" @click="gotosend">
        <div class="names">服务类型</div>
        <div class="input">
          <!-- <input type="text" placeholder="请输入手机号"> -->
          <div class="xialaxuanze">
            <span :style="{color:webcolor}">{{ xialaxuanze }}</span>
            <div :class="backimageweblist"></div>
            <div :class="widthsonxuan">
              <div class="widthlist" @click="weblistson('医疗直播')">医疗直播</div>
              <div class="widthlist" @click="weblistson('微官网定制')">微官网定制</div>
              <div class="widthlist" @click="weblistson('视频制作')">视频制作</div>
              <div class="widthlist" @click="weblistson('线上云展厅')">线上云展厅</div>
            </div>
          </div>
        </div>
      </div>
      <div class="inputweb">
        <div class="names">需求描述</div>
        <div class="input">
          <textarea v-model="form.date1" class="textlist" type="text" placeholder="请详细描述您的需求（必填）" maxlength="500"></textarea>
        </div>
      </div>
    </div>
    <div class="buttonweblist" @click="tijiaonumber">提交</div>
   </div>
   <div class="phonenumberlist" v-if="liuyanshows">
    <div class="phonelistweb">010-67760432</div>
   </div>
  </div>
</template>
<script>
import { Picker } from "emoji-mart-vue"; //引入组件
import qs from 'qs';
export default {
  data(){
    return {
      textarea2:'',
      liuyanshow:false,
      liuyanshows:false,
      liuyanban:[
        {id:0,names:'',title:'您好,可以随时留言',figure:0},
        {id:1,names:'',title:'留下您的电话看到后第一时间联系您',figure:0},
        {id:2,names:'小月',title:'三年内三年内',figure:1},
      ],
      app:false,
      form: {
          name: '',
          phonenumber: '',
          date1: '',
        },
        backimageweblist:'backimageweblist',
        widthsonxuan:'widthsonxuans',
        xialaxuanze:'请选择服务类型',
        webcolor:'#999999'
    }
  },
  methods:{
    gotoshowweb(){
      // console.log(this.textarea2,this.liuyanban.length)
      let c=this.liuyanban.length
      let b=this.textarea2
      this.$axios.post(`http://api.qingyunke.com/api.php?key=free&appid=0&msg=测试`).then(res=>{
        console.log(res)
      })
      let a={id:c,title:b,names:'小月',figure:1}
      this.liuyanban.push(a)
      this.textarea2=''
      this.app=false
     
    },
    addEmoji (e) {
      this.textarea2 += e.native;
    },
    appweblist(){
     this.app=false
    },
    gotosend(){
      if(this.backimageweblist=='backimageweblist'){
        this.backimageweblist='backimageweblists'
        this.widthsonxuan='widthsonxuan'
      }else if(this.backimageweblist=='backimageweblists'){
        this.backimageweblist='backimageweblist'
        this.widthsonxuan='widthsonxuans'
      }
    },
    weblistson(e){
      console.log(e)
      this.xialaxuanze=e
      this.webcolor='#000'
    },
    tijiaonumber(){
      console.log(this.form,this.xialaxuanze)
      let pattern = /^((0\d{2,3}(-)?\d{7,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/
      if(this.form.name==''){
        alert('请填写您的姓名')
      }else if(!pattern.test(this.form.phonenumber)){
        alert('请输入正确的手机号')
      }else if(this.form.date1==''){
        alert('请填写您的需求')
      }else if(this.xialaxuanze=='请选择服务类型'){
        alert('请选择服务类型')
      }else {
        let app = qs.stringify({
           name: this.form.name,
           phone:this.form.phonenumber,
           type:this.xialaxuanze,
           message:this.form.date1
         });
        let apl = 'https://tx4qecdn.tongxianglive.cn/tx3/public/index.php/index/Txsa/homepagemessage';
        this.$axios.post(apl, app).then((res) => {
          console.log(res)
          if(res.data.code==='9999'){
            alert('提交成功，请注意您的来电')
            this.liuyanshow=false
          }else {
            alert('提交失败，请稍后重试')
          }
        })
          
      }
    }
  },
  components:{
    Picker
  },
}
</script>
<style lang="less" scoped>
.phonenumber {
  position: fixed;
  bottom: 25px;
  right: 5px;
  .phonelist {
    width: 96px;
    height: 96px;
    background: linear-gradient(180deg, #6652F9 0%, #A498FF 100%);
    border-radius: 6px 6px 6px 6px;
    margin-top: 25px;
    position: relative;
    .imagetitle {
      // width: 96px;
      // margin: 0 auto;
      .image {
        margin: 0 auto;
        width: 41px;
        height: 41px;
        padding: 18px 0 12px 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title {
        // width: 96px;
        // height: 24px;
        text-align: center;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
      }
    }
    .backweb {
      position: absolute;
      width: 83px;
      height: 83px;
      background-image: url('../../../../image/电话/投影@2x.png');
      right: -10px;
      bottom: -15px;
      background-size: 100% 100%;
      // z-index: -1;
    }
  }
}
.showborder {
  width: 521px;
  height: 599px;
  position: fixed;
  right: 275px;
  bottom: 137px;
  background: #fff;
  z-index: 101;

  .navlistimage {
    width: 100%;
    height: 48px;
    background-image: url('../../../../image/0\ 102_slices/0 102@2x.png');
    position: relative;
    .compostallwebx {
      position: absolute;
      top: 50%;
      right: 20px;
      font-size: 44px;
      height: 20px;
      width: 20px;
      line-height: 20px;
      color: #fff;
      // transform: ;
      transform: translate(0,-50%) rotate(45deg);
    }
  }
  .contitle {
    width: 403px;
    margin: 0 auto;
    margin-top: 33px;
    .titlelist {
      font-size: 24px;
      font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
      font-weight: bold;
      color: #6352F9;
    }
    .sontitle {
      font-size: 15px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-top: 23px;
    }
  }
  .fromnlisr {
    width: 403px;
    margin: 0 auto;
    // height: 42px;
    // border: 1px solid #ccc;
    margin-top: 8px;
    .inputweb {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 15px;
      .names {
        width: 60px;
        line-height: 42px;
        font-size: 15px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }
      .input {
        width: 332px;
        // height: 42px;
        position: relative;
        // padding: 0 16px 0 12px;
        // box-sizing:content-box;
        input {
          width: 302px;
          padding: 0 15px 0 15px;
          height: 42px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #000;
          border-radius: 6px;
          border: 1px solid #999999;
         
        }
        .xialaxuanze {
          width: 302px;
          padding: 0 15px 0 15px;
          height: 42px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          border-radius: 6px;
          border: 1px solid #999999;
          line-height: 42px;
          .backimageweblist {
            width: 18px;
            height: 18px;
            position: absolute;
            top: 12px;
            right: 12px;
            background-image: url('../../../../image/0\ 102_slices/Frame@2x.png');
            background-size: 100% 100%;
          }
          .backimageweblists {
            width: 18px;
            height: 18px;
            position: absolute;
            top: 12px;
            right: 12px;
            background-image: url('../../../../image/0\ 102_slices/Frame@2x.png');
            background-size: 100% 100%;
            transform: rotate(180deg);
            transition: rotate 1s;
          }
          .widthsonxuan {
            z-index: 102;
            width: 115px;
            height: 168px;
            background: #FFFFFF;
            box-shadow: 2px 2px 12px 0px rgba(0,0,0,0.08);
            border-radius: 6px 6px 6px 6px;
            position: absolute;
            top: 43px;
            left: 50%;
            // transform: translate(-50% 0);
            transform: translate(-50%,0);
            transition: height 1s;
            overflow: hidden;
          }
          .widthlist {
            padding: 0 20px;
            font-size: 15px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
          }
          .widthsonxuans {
            width: 115px;
            height: 0;
            background: #FFFFFF;
            box-shadow: 2px 2px 12px 0px rgba(0,0,0,0.08);
            border-radius: 6px 6px 6px 6px;
            position: absolute;
            top: 43px;
            left: 50%;
            // transform: translate(-50% 0);
            transform: translate(-50%,0);
            overflow: hidden;
          }
        }
        .textlist {
          width: 302px;
          height: 123px;
          padding: 0 15px;
          padding-top: 12px;
          border-radius: 6px 6px 6px 6px;
          opacity: 1;
          border: 1px solid #999999;
          resize:none;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
        }
      }
    }
  }
  .buttonweblist {
    width: 403px;
    height: 52px;
    background: #6352F9;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    text-align: center;
    line-height: 52px;
    margin: 0 auto;
    margin-top: 33px;
    font-size: 16px;
    font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
    font-weight: bold;
    color: #FFFFFF;
  }
}
.phonenumberlist {
  width: 145px;
  height: 43px;
  background: #FFFFFF;
  box-shadow: 2px 2px 13px 0px rgba(0,0,0,0.08);
  border-radius: 6px 6px 6px 6px;
  position: fixed;
  right: 102px;
  bottom: 170px;
  // background: #fff;
  z-index: 102;
  
  .phonelistweb {
    font-size:16px;
    text-align: center;
    line-height: 43px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;
color: #6352F9;
  }
}

</style>