<template>
  <div class="after">
    <div class="finish">
     <div class="son">
      <div class="service">
        <div class="hdivsend">产品服务</div>
        <div class="content">
          <div class="spanlist">医疗直播</div>
          <div class="spanlist">微官网定制</div>
          <div class="spanlist">视频制作</div>
          <div class="spanlist">线上云展厅</div>
        </div>
      </div>
      <div class="service">
        <div class="hdivsend">客户案例</div>
        <div class="content">
          <div class="spanlist">医疗直播</div>
          <div class="spanlist">微官网定制</div>
          <div class="spanlist">视频制作</div>
          <div class="spanlist">线上云展厅</div>
        </div>
      </div>
      <div class="service">
        <div class="hdivsend">关于我们</div>
        <div class="content">
          公司介绍
        </div>
      </div>
      <div class="service">
          <div class="content">咨询电话：</div>
          <div class="hdivsends">010-67760432</div>
          <div class="contents">合作邮箱: <span class="list">yizhi@yizhiyihui.cn</span></div>
          <div class="contents">联系地址: <span class="list">北京市朝阳区黄厂南里3号院20号楼1110室</span></div>
        </div>
     </div>
     <div class="sonlist">版权所有  医知医汇科技有限公司 | 备案号：京ICP备2020048198号</div>
    </div>
    
  </div>
</template>
<script>
export default {
  data(){
    return{}
  }
}
</script>
<style lang="less" scoped>
.after {
  width: 100vw;
  min-width: 1200px;
  height: 420px;
  background-color: #000000;
  .finish {
    width: 1200px;
    height: 420px;
    margin: 0 auto;
    position: relative;
    .son {
      padding-top: 75px;
      color: #fff;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .service {
        // margin-right: 253px;
        .hdivsend {
          font-size: 18px;
          font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
          font-weight: bold;
          color: #FFFFFF;
          margin-bottom: 51px;
        }
        .hdivsends {
          font-size: 34px;
          font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 40px;
          margin: 16px 0;
        }
        .content {
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          .spanlist {
            margin-bottom: 16px;
          }
          
        }
        .contents {
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin-bottom: 23px;
          .spanlist {
            margin-bottom: 16px;
          }
          .list {
            font-size: 18px;
            font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
            font-weight: bold;
            color: #FFFFFF;
          }
        }
      }
    }
    .sonlist {
      position: absolute;
      bottom: 30px;
     
      left: 0;
      color: #FFFFFF;
      text-align: center;
      font-size: 12px;
      margin-top: 30px;
    }
    
  }
}
</style>
